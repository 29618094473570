import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@apollo/client-react-streaming/dist/index.cc.cjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@liquorice/next-apollo/src/ApolloWrapper/ApolloWrapperInternal.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2FAppRoot%2FAppRoot.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll83eG1jZTUwIHsKICBvdmVyZmxvdzogaGlkZGVuOwogIGRpc3BsYXk6IGZsZXg7CiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjsKICBtaW4taGVpZ2h0OiAxMDB2aDsKfQouXzd4bWNlNTEgewogIGZsZXgtZ3JvdzogMTsKfQ%3D%3D%22%7D");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/sanitize.css/sanitize.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/sanitize.css/forms.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/sanitize.css/typography.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/_DevToolbar/DevToolbar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/AppRoot/AppRoot.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/theme/global.css");
